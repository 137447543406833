<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>夺宝配置</template>
    </breadcrumb-nav>
    <el-card class="box-card goods-add" shadow="never">
      <el-button type="primary" size="small" @click="submitUpdate">提交</el-button>
      <el-button type="primary" size="small" @click="$router.back(-1)">返回</el-button>
    </el-card>
    <el-card>
      <el-form size="mini" :model="huntForm" :rules="huntFormRules" ref="form" label-width="100px"
               label-position="right">
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="24">
            <el-form-item label="商品名称" prop="name">
              <el-input :disabled="true" style="width: 100%" v-model="base.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" style="margin-bottom: 10px">
          <el-col :span="6">
            <el-form-item label="初始人数" prop="name">
              <el-input type="number" style="width: 100%" v-model="huntForm.origin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="开奖人数" prop="name">
              <el-input type="number" style="width: 100%" v-model="huntForm.reached"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="消耗金币" prop="name">
              <el-input type="number" style="width: 100%" v-model="huntForm.cost"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="延迟开奖" prop="name">
              <el-input type="number" style="width: 100%" v-model="huntForm.delay"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        border
        :data="skuList"
        style="width: 100%;margin:20px 0 "
      >
        <el-table-column label="选择" align="center" width="65">
          <template scope="scope">
            <el-radio  :label="scope.$index"
                      v-model="radio"
                      @change.native="getCurrentRow(scope.row)">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="80px" align="center">
        </el-table-column>
        <el-table-column
          v-for="(item, index) in attrs"
          :key="index"
          :prop="item"
          :label="item"
        >
        </el-table-column>
        <el-table-column
          prop="stock"
          label="库存"
        ></el-table-column>
        <el-table-column
          prop="sales"
          label="销量"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="销售价"
        ></el-table-column>
        <el-table-column
          prop="supplyPrice"
          label="成本价"
        ></el-table-column>
        <el-table-column
          prop="marketPrice"
          label="市场建议价"
        ></el-table-column>
        <el-table-column prop="rate" label="利润率">
          <template slot-scope="scope">
            {{ scope.row.rate | formatFloat }}
          </template>
        </el-table-column>
        <el-table-column prop="salesRate" label="销售利润率">
          <template slot-scope="scope">
            {{ scope.row.salesRate | formatFloat }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { getHuntConfigRequest, updateHuntRequest } from '../../../network/hunt'
import { getGoodsDetail } from '../../../network/goods'
export default {
  name: 'HuntConfig',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatFloat (value) {
      return value.toFixed(2)
    }
  },
  data () {
    return {
      huntForm: {
        id: 0,
        origin: 0,
        reached: 0,
        cost: 0,
        skuId: 0,
        skuDesc: '',
        skuPrice: 0.0,
        delay: 0
      },
      attrs: [],
      skuList: [],
      goodsDetail: {},
      base: '',
      radio: '',
      huntFormRules: {
        reached: [
          {
            required: true,
            message: '请输入开奖人数',
            trigger: 'blur'
          }
        ],
        cost: [
          {
            required: true,
            message: '请输入消耗金币',
            trigger: 'blur'
          }
        ],
        skuId: [
          {
            required: true,
            message: '请选择skuId',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.huntForm.id = parseInt(this.$route.query.id)
    this.getHuntConfigDetail()
  },
  methods: {
    getCurrentRow (row) {
      this.huntForm.skuId = row.skuId
      this.huntForm.skuPrice = parseFloat(row.price)
      /* for (const item of this.skuList) {
        if (item.skuId !== row.skuId) {
          item.radio = false
        }
      } */
      // this.huntForm.skuDesc = ''
      console.log('row:', row)
      console.log('this.arrs:', this.attrs)
      const arrs = []
      for (const attr of this.attrs) {
        arrs.push(row[attr])
      }
      if (arrs.length > 0) {
        this.huntForm.skuDesc = arrs.join('-')
      }
      console.log('arrs:', this.arrs, ',desc:', this.huntForm.skuDesc)
      console.log('select radio:', this.radio, ';skuId:', this.huntForm.skuId)
    },
    getHuntConfigDetail () {
      getHuntConfigRequest({ id: this.huntForm.id }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取夺宝配置信息失败', 'error')
        }
        this.huntForm.origin = result.data.origin
        this.huntForm.reached = result.data.reached
        this.huntForm.cost = result.data.cost
        this.huntForm.skuId = result.data.skuId
        this.huntForm.skuDesc = result.data.skuDesc
        this.huntForm.skuPrice = parseFloat(result.data.skuPrice)
        this.huntForm.delay = result.data.delay
        console.log('this.hunt:', this.huntForm)
        this.getGoodsDetail(result.data.goodsId)
      })
    },
    getGoodsDetail (goodsId) {
      getGoodsDetail(goodsId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商品信息失败', 'error')
        }
        this.goodsDetail = result.data
        this.base = this.goodsDetail.base
        this.attrs = this.goodsDetail.specs.specs.attrs
        this.skuList = []
        for (const [idx, item] of this.goodsDetail.specs.skuList.entries()) {
          const skuItem = {
            price: item.price,
            oldPrice: item.oldPrice,
            stock: item.stock,
            sales: item.sales,
            marketPrice: item.marketPrice,
            salesRate: item.salesRate,
            rate: item.rate,
            supplyPrice: item.supplyPrice,
            skuId: item.skuId
          }
          if (skuItem.skuId === this.huntForm.skuId) {
            this.radio = idx
          }
          if (this.attrs.length <= 0 || this.attrs[0] === '') {
            this.attrs = []
            for (const attr in item.attrs) {
              this.attrs.push(attr)
            }
          }
          for (const attr of this.attrs) {
            skuItem[attr] = item.attrs[attr]
          }
          this.skuList.push(skuItem)
        }
        console.log('this.skuList:', this.skuList)
      })
    },
    submitUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        if (this.huntForm.reached <= 0) {
          this.$message.warning('请输入开奖人数！')
          return
        }
        if (this.huntForm.cost <= 0) {
          this.$message.warning('请输入消耗金币！')
          return
        }
        if (this.huntForm.skuId <= 0) {
          this.$message.warning('请选择夺宝商品的规格！')
          return
        }
        updateHuntRequest({
          id: parseInt(this.huntForm.id),
          origin: parseInt(this.huntForm.origin),
          reached: parseInt(this.huntForm.reached),
          cost: parseInt(this.huntForm.cost),
          skuId: parseInt(this.huntForm.skuId),
          skuDesc: this.huntForm.skuDesc,
          skuPrice: parseFloat(this.huntForm.skuPrice),
          delay: parseInt(this.huntForm.delay)
        }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('修改夺宝配置失败', 'error')
          }
          this.alertMessage('修改夺宝配置成功', 'success')
          this.$router.back()
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
