// 获取任务列表
import { request } from './request'

export function listHuntConfigRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/hunt/list/goods/config',
    method: 'get',
    params
  })
}

export function getHuntConfigRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/hunt/detail/config',
    method: 'get',
    params
  })
}

// 修改hunt config state
export function updateHuntConfigStateRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/hunt/update/config/state',
    method: 'post',
    data: { ...params }
  })
}

// 修改hunt state 起停状态
export function updateHuntStateRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/hunt/update/state',
    method: 'post',
    data: { ...params }
  })
}

// 修改hunt配置
export function updateHuntRequest (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/hunt/update',
    method: 'post',
    data: { ...params }
  })
}
